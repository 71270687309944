import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import snackbarUtils from '../../utils/SnackbarUtils';
import { getCustomerDashboardDIDsList, getFullLocationsList, getFullOrganizationsList } from './shared.action';
import { LocationListItemType } from '../../types/location/location.type';

export interface SharedState {
  customerDashboardRouteParams: RouteParamsType;
  customerDashboardDIDsList: PaginationType<DIDsListItemType> | null;
  customerDashboardDIDsListFetchStatus: FETCH_STATUS;
  fullOrganizationsList: Array<{
    bobo_enabled: boolean;
    hasChildren: boolean;
    id: number;
    level: number;
    name: string;
  }> | null;
  fullOrganizationsListFetchStatus: FETCH_STATUS;
  fullLocationsList: PaginationType<LocationListItemType> | null;
  fullLocationsListFetchStatus: FETCH_STATUS;
}

export const initialCustomerDashboardRouteParams = {
  page: 1,
  limit: 10,
  order: '-id',
  q: '',
  status: '',
};

const INIT_STATE: SharedState = {
  customerDashboardRouteParams: initialCustomerDashboardRouteParams,
  customerDashboardDIDsList: null,
  customerDashboardDIDsListFetchStatus: FETCH_STATUS.NULL,
  fullOrganizationsList: null,
  fullOrganizationsListFetchStatus: FETCH_STATUS.NULL,
  fullLocationsList: null,
  fullLocationsListFetchStatus: FETCH_STATUS.NULL,
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState: INIT_STATE,
  reducers: {
    setCustomerDashboardDIDsList: (state, action) => {
      state.customerDashboardDIDsList = action.payload;
    },
    setCustomerDashboardDIDsListFetchStatus: (state, action) => {
      state.customerDashboardDIDsListFetchStatus = action.payload;
    },
    setFullOrganizationsList: (state, action) => {
      state.fullOrganizationsList = action.payload;
    },
    setFullOrganizationsListFetchStatus: (state, action) => {
      state.fullOrganizationsListFetchStatus = action.payload;
    },
    setFullLocationsList: (state, action) => {
      state.fullLocationsList = action.payload;
    },
    setFullLocationsListFetchStatus: (state, action) => {
      state.fullLocationsListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'dashboard-dids') {
        state.customerDashboardRouteParams = {
          ...state.customerDashboardRouteParams,
          ...payload.data,
          limit: isNaN(payload.data.limit) ? state.customerDashboardRouteParams.limit : payload.data.limit,
        };
      }
    });

    // CUSTOMER DASHBOARD DID LIST
    builder.addCase(getCustomerDashboardDIDsList.pending, state => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCustomerDashboardDIDsList.rejected, (state, action) => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.REJECTED;
      state.customerDashboardDIDsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCustomerDashboardDIDsList.fulfilled, (state, action) => {
      state.customerDashboardDIDsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.customerDashboardDIDsList = action.payload;
    });

    // FULL ORGANIZATIONS LIST
    builder.addCase(getFullOrganizationsList.pending, state => {
      state.fullOrganizationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullOrganizationsList.rejected, (state, action) => {
      state.fullOrganizationsListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullOrganizationsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getFullOrganizationsList.fulfilled, (state, action) => {
      state.fullOrganizationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullOrganizationsList = action.payload;
    });

    // FULL LOCATIONS LIST
    builder.addCase(getFullLocationsList.pending, state => {
      state.fullLocationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullLocationsList.rejected, (state, action) => {
      state.fullLocationsListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullLocationsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getFullLocationsList.fulfilled, (state, action) => {
      state.fullLocationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullLocationsList = action.payload;
    });
  },
});

export const {
  setCustomerDashboardDIDsList,
  setCustomerDashboardDIDsListFetchStatus,
  setFullLocationsListFetchStatus,
  setFullOrganizationsListFetchStatus,
  setFullOrganizationsList,
  setFullLocationsList,
} = sharedSlice.actions;

export default sharedSlice.reducer;
